import React from "react";
import {headerText, headerButton} from "./HeaderUtility.mjs";
import "./Header.css";

export class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            color: "" // Initial color
        };
    }

     createButtons()  {
        let buttons = [];
        let buttonNames = ["Projects", "Resume", "Contact"];
        for (let i = 0; i < buttonNames.length; i++) {
            buttons.push(headerButton(buttonNames[i], "Header-button"+ this.state.color, () => this.changeColor(buttonNames[i])));
        }
        console.log("Buttons created: ", buttons);
        return buttons
    }

    changeColor(buttonName) {
        let newColor;
        switch (buttonName) {
            case "Projects":
                newColor = "-red";
                break;
            case "Resume":
                newColor = "-green";
                break;
            case "Contact":
                newColor = "-blue";
                break;
            default:
                newColor = "";
        }
        this.setState({ color: newColor });
       
    }

    render() {
        console.log("Current state: ", this.state);
        return (
            <header style={{margin : "0"}}>
            <div className="Header-content">
             {headerText("Ryan Joseph Cunningham", "Header-text", this.createButtons())}
            </div>
            </header>
        );
    }
}