import React from 'react';

export class Footer extends React.Component {
    render() {
        return (
            <footer className='App-footer'>
                <p>
                    Built From Build Folder in a script
                </p>
            </footer>
        );
    }
}