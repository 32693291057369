import React from 'react';

export class Main extends React.Component {
    render() {
        return (
            <main className='App-main'>
                <p>
                    Content Switch Up from a script
                </p>
            </main>
        );
    }
}