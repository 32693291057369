import { Header } from './model/HeaderObjects/Header';
import { Main } from './model/MainObjects/Main';
import { Footer } from './model/FooterObjects/Footer';
import './App.css';

function App() {
  return (
    <div className='App'>
      <Header />
      <Main />
      <Footer />
    </div>
 
  );
}

export default App;
